import { createContext, useState } from "react";
import { Outlet } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { useAccount } from "../../hooks/useAccount";
import { apiV1 } from "../../lib/axios";
import moment from "moment";
import { toast } from "react-toastify";

export const TicketContext = createContext({});

export function TicketProvider({}) {
  const [step, setStep] = useState(1);
  const [ticketLoader, setTicketLoader] = useState(false);
  const [boleto, setBoleto] = useState({});
  const [boletos, setBoletos] = useState([]);
  const [boletosLoader, setBoletosLoader] = useState(false);
  const [emitedTicketCod, setEmitedTicketCod] = useState("");

  const { account } = useAccount();
  const { user } = useAuth();

  async function fetchTickets() {
    setBoletosLoader(true);

    await apiV1.get("/boleto/list").then((response) => {
      setBoletos(response.data.data.rows);

      setBoletosLoader(false);
    });
  }

  async function getTicket(codBoleto) {
    setTicketLoader(true);

    await getTicketFitbank(codBoleto);

    setTicketLoader(false);
  }

  async function getTicketFitbank(codBoleto) {
    try {
      const response = await apiV1.get(`/payment/info-cip/fitbank/${codBoleto}`);
  
      const res = response.data.ticket;
      
      setStep(2);
      setBoleto(res);
    } catch (error) {
      if (error.response && error.response.data) {
        const errorMessage = error.response.data.message ;
        
        toast.error(` ${errorMessage}`);
      } else {
        toast.error('Erro ao tentar obter informações do boleto. Verifique a conexão e tente novamente.');
      }
    }
  }

  async function payTicket(data) {
    setTicketLoader(true);

    const body = {
      barCode: boleto.barcode,
      dueDate: boleto.dueDate || moment().tz('America/Sao_Paulo').toISOString(),
      value: boleto.value,
      receiverName: data.receiverName,
      receiverTaxNumber: data.receiverTaxNumber,
      otp: data.otp
    };
    
    await payTicketFitbank(body, data);
    setTicketLoader(false);
  }

  async function payTicketFitbank(body, data) {
    await apiV1
      .post("/payment/fitbank/pay-ticket", body)
      .then(async (response) => {

        await getComprovante({
          ...data,
          transactionId: response.data.payment.id,
        });
        setStep(3);
      })
      .catch((err) => {
        toast.error("Erro ao tentar realizar pagamento.");
      });
  }

  async function emitTicketFitbank(data) {
    setTicketLoader(true);

    apiV1
      .post("/boleto/fitbank/emit", data)
      .then((response) => {
        setTicketLoader(false);
        setEmitedTicketCod(response.data.data.codigoBarras);
        localStorage.setItem("boletoPDF", response.data.data.boletoBase64);
        setStep(3);
      })
      .catch((err) => {
        toast.error('Erro ao tentar emitir boleto. Verifique a conexão e tente novamente.');
        setTicketLoader(false);

      });
  }

  async function getComprovante(data) {
    await apiV1.post("/voucher/generate", data)
      .then((response) => {

        const res = response.data.data;

        localStorage.removeItem("comprovante");
        localStorage.setItem("comprovante", res.base64);
      })
      .catch((err) => {
        
      });
  }

  return (
    <TicketContext.Provider
      value={{
        ticketLoader,
        setTicketLoader,
        step,
        boleto,
        boletos,
        boletosLoader,
        emitedTicketCod,
        getTicket,
        setStep,
        setTicketLoader,
        payTicket,
        fetchTickets,
        emitTicketFitbank,
      }}
    >
      <Outlet />
    </TicketContext.Provider>
  );
}
