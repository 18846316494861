import React from "react";

const MobileButtons = ({ googleButtonWidth = 150, appleButtonWidth = 150, googleButtonHeight = 50, appleButtonHeight = 50 }) => {
  const googleStoreLink = process.env.REACT_APP_GOOGLE_STORE_URL;
  const appleStoreLink = process.env.REACT_APP_APPLE_STORE_URL;

  if (!googleStoreLink && !appleStoreLink) return null;

  return (
    <div className="d-flex justify-content-center align-items-center gap-2">
      {googleStoreLink && (
        <a href={googleStoreLink} target="_blank" rel="noopener noreferrer" aria-label="Baixar no Google Play">
          <img src="/images/google-badge.png" alt="Baixar no Google Play" width={googleButtonWidth} height={googleButtonHeight} />
        </a>
      )}

      {appleStoreLink && (
        <a href={appleStoreLink} target="_blank" rel="noopener noreferrer" aria-label="Baixar na App Store">
          <img src="/images/apple-badge.svg" alt="Baixar na App Store" width={appleButtonWidth} height={appleButtonHeight} />
        </a>
      )}
    </div>
  );
};

export default MobileButtons;
