import styled from "styled-components";

export const RegisterPJContainer = styled.div`
  color: ${({ theme }) => theme.colors.black};
  max-width: 40rem;
  width: 100%;
  max-height: 39.75rem;
  padding: 3.1875rem 1.5rem ;
  background: ${({ theme }) => theme.colors["bg-color"]};
  border: 0;
  border-radius: 12px;
  overflow-y: auto;
  align-items: center;
  justify-content: center;

  &::-webkit-scrollbar {
    width: 0.25rem;
  }

  &::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.colors['primary-color']};
    border-radius: 20px;
  }

  & p {
    font-size: 14px;
    text-align: center;
  }
`;

