import './styles.css';
import { BoxModal } from '../../../components/boxModal';
import { PixKey } from '../pix-key'
import * as RadioGroup from '@radix-ui/react-radio-group';
import BeatLoader from "react-spinners/BeatLoader";
import { usePixKey } from '../../../hooks/usePixKey';

export const CreatePix = ({handleCloseModal, userData, loader, override, handleCreateNewKey, showKeyDeletedModal, typeKey, setTypeKey, isPF, showConfirmationCodeModal, closeConfirmationCodeModal, startPuller}) => {
  const { pixKeys } = usePixKey();
  
  const taxNumber = userData.taxNumber.replace(/[^\d]+/g, "");
  const email = userData.mail;
  const phoneNumber = `+55${userData.phoneNumber.replace(/[^\d]+/g, "")}`;

  const keysSet = new Set(pixKeys.map(({ key }) => key));

  const keysStatus = {
    hasTaxNumberKey: keysSet.has(taxNumber),
    hasEmailKey: keysSet.has(email),
    hasPhoneKey: keysSet.has(phoneNumber),
  };

  const KEY_TYPE = {
    CPF: 0,
    CNPJ: 1,
    MAIL: 2,
    PHONE: 3,
    RANDOM: 4,
  }

  const keysLimit = isPF ? 5 : 20;
  const pixKeysReachedLimit = pixKeys.length >= keysLimit;

  const radioOptions = [
    { type: isPF ? KEY_TYPE.CPF : KEY_TYPE.CNPJ, id: isPF ? "cpf" : "cnpj", label: isPF ? "CPF" : "CNPJ", disabled: keysStatus.hasTaxNumberKey, keyValue: taxNumber },
    { type: KEY_TYPE.MAIL, id: "email", label: "E-mail", disabled: keysStatus.hasEmailKey, keyValue: email },
    { type: KEY_TYPE.PHONE, id: "tel", label: "Celular", disabled: keysStatus.hasPhoneKey, keyValue: phoneNumber },
    { type: KEY_TYPE.RANDOM, id: "random", label: "Chave aleatória", disabled: false },
  ];

  return (
    <BoxModal title='Gerenciamento de Chaves Pix' maxWidth={800} maxHeight={801} close={handleCloseModal}>
      <div className='keys-modal-content'>
        <div className='keys-modal-section'>
          <h3>Minhas Chaves</h3>

          <div className='my-keys'>
            {!loader ? (
              pixKeys.length > 0 ? (
                pixKeys.map((item) => (
                  <PixKey
                    key={item.id}
                    id={item.id}
                    type={item.type}
                    keyValue={item.key}
                    startPuller={startPuller}
                    status={item.status}
                    showConfirmationCodeModal={showConfirmationCodeModal}
                    showKeyDeletedModal={showKeyDeletedModal}
                    setTypeKey={setTypeKey}
                    loader={loader}
                  />
                ))
              ) : (
                <span>Sem chaves no momento.</span>
              )
            ) : (
              <BeatLoader
                color={"var(--secondary-color)"}
                loading={loader}
                css={override}
                size={20}
              />
            )}
          </div>
        </div>

        <div className='keys-modal-section'>
          <h3>Cadastrar nova Chave</h3>

          <form className='new-key-container' onSubmit={handleCreateNewKey}>
            <RadioGroup.Root
              className="new-key-radio-root"
              defaultValue="4"
              value={typeKey}
              onValueChange={(value) => setTypeKey(value)}
              id='cpf'
              required
            >
              {radioOptions.map(({ type, id, label, disabled, keyValue }) => (
                <div className='new-key-radio-item' key={id}>
                  <RadioGroup.Item className="new-key-radio-button" value={type} id={id} disabled={disabled || pixKeysReachedLimit}>
                    <RadioGroup.Indicator className="new-key-radio-indicator" />
                  </RadioGroup.Item>
                  <label className="new-key-radio-label" htmlFor={id}>
                    {label} {disabled && ' - Chave já cadastrada'}
                    <strong>{keyValue}</strong>
                  </label>
                </div>
              ))}
            </RadioGroup.Root>

            <div className='d-flex justify-content-between align-items-center'>
              <button disabled={(typeKey == null ? true : (loader || pixKeysReachedLimit))} className='new-key-button'>{loader ? 'Solicitando...':'Solicitar chave pix'}</button>
              <span>Número máximo de chaves permitidas: {keysLimit}</span>
            </div>
          </form>
        </div>
      </div>
    </BoxModal>
  );
}

