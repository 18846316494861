import MobileButtons from "../../../components/mobile-buttons/mobileButtons";
import { RegisterPJContainer } from "./styles"

export const RegisterPF = () => {
  return (
    <RegisterPJContainer>
      <p>A abertura da sua conta Pessoa Física não está disponível pelo navegador web.</p>
      <p>Para continuar, baixe nosso aplicativo. É rápido, simples e intuitivo!</p>

      <MobileButtons
        googleButtonWidth="150"
        googleButtonHeight="50"
        appleButtonWidth="150"
        appleButtonHeight="50"
      />
    </RegisterPJContainer>
  )
}