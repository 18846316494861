import { useRegister } from "../../../hooks/useRegister"
import { CompleteRegister } from "../CompleteRegister"
import { AddressForm } from "./AddressForm"
import { CompanyDataForm } from "./CompanyDataForm"
import { DocumentsForm } from "./DocumentsForm"
import { RepresentativeForm } from "./RepresentativeForm"
import { MfaForm } from "./MfaForm"
import { RegisterPJContainer } from "./styles"
import { Resume } from "./Resume"

export const RegisterPJ = () => {
  const { success, step } = useRegister();

  return (
    <>
      {
        !success ? (
          <RegisterPJContainer>
            {step === 1 && <CompanyDataForm />}
            {step === 2 && <MfaForm />}
            {step === 3 && <AddressForm />}
            {step === 4 && <RepresentativeForm />}
            {step === 5 && <DocumentsForm />}
            {step === 6 && <Resume />}
          </RegisterPJContainer>
        ) : <CompleteRegister />
      }
    </>
  )
}