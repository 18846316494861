import React, { useEffect, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Link } from 'react-router-dom';
import IntlCurrencyInput from 'react-intl-currency-input';
import './Style.css';
import { uploadS3 } from '../../../../services/uploadS3';
import {
  getLimitsFitBank,
  setLimitsFitBank,
  requestPixLimit,
} from '../../../../services/pix.service';
import BeatLoader from 'react-spinners/BeatLoader';
import { css } from '@emotion/react';
import { toast } from 'react-toastify';
import Asterisk from '../../../../shared/components/asterisk/asterisk';
import LimitsMessage from '../../../../shared/components/limits-message/limitsMessage';
import ReactModal from 'react-modal';
import { BoxModal } from '../../../boxModal';
import { FilterItem } from '../../../extrato/styles';
import MessageExtract from '../../../../shared/components/limits-message/messageExtract';
import MessageOrigin from '../../../../shared/components/limits-message/messageOrigin';
import { documentTypes } from '../../../../services/enums';
import { getDocumentFormat } from '../../../../utils/documentFormat';
import { apiV1 } from '../../../../lib/axios';
import moment from 'moment/moment';
import { AiOutlineInfoCircle } from 'react-icons/ai';

const LimitesPix = () => {
  const [limiteDiario, setLimiteDiario] = useState(0);
  const [novoLimiteDiario, setNovoLimiteDiario] = useState(0);
  const [originalLimiteDiario, setOriginalLimiteDiario] = useState(0);

  const [limiteNoturno, setLimiteNoturno] = useState(0);
  const [novoLimiteNoturno, setNovoLimiteNoturno] = useState(0);
  const [originalLimiteNoturno, setOriginalLimiteNoturno] = useState(0);

  const [limiteTransacao, setLimiteTransacao] = useState(0);
  const [novoLimiteTransacao, setNovoLimiteTransacao] = useState(0);
  const [originalLimiteTransacao, setOriginalLimiteTransacao] = useState(0);

  const [modalLoading, setModalLoading] = useState(false);

  const [diarioChanged, setDiarioChanged] = useState(false);
  const [noturnoChanged, setNoturnoChanged] = useState(false);
  const [transacaoChanged, setTransacaoChanged] = useState(false);

  const [diarioLoading, setDiarioLoading] = useState(false);
  const [noturnoLoading, setNoturnoLoading] = useState(false);
  const [transacaoLoading, setTransacaoLoading] = useState(false);

  const [modalLimiteDiario, setModalLimiteDiario] = useState(0);
  const [modalLimiteNoturno, setModalLimiteNoturno] = useState(0);
  const [modalLimiteTransacao, setModalLimiteTransacao] = useState(0);
  const [_document, _setDocument] = useState(null);
  const [loader, setLoader] = useState(false);
  const [hasChanges, setHasChanges] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectLimits, setSelectLimits] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const [justification, setJustification] = useState('');
  const [diarioSolicitado, setDiarioSolicitado] = useState(true);
  const [noturnoSolicitado, setNoturnoSolicitado] = useState(true);
  const [transacaoSolicitado, setTransacaoSolicitado] = useState(true);

  const checkLimitRequests = async () => {
    try {
      const response = await getLimitsFitBank();
      const { lastDailyRequest, lastOvernightRequest, lastTransactionRequest } =
        response.data;
      const today = moment().startOf('day');

      const checkRequestStatus = (lastRequestDate) => {
        if (!lastRequestDate) return false;
        const lastRequest = moment(lastRequestDate);
        const daysSinceLastRequest = today.diff(lastRequest, 'days');
        return daysSinceLastRequest < 90;
      };

      setDiarioSolicitado(checkRequestStatus(lastDailyRequest));
      setNoturnoSolicitado(checkRequestStatus(lastOvernightRequest));
      setTransacaoSolicitado(checkRequestStatus(lastTransactionRequest));

      setNovoLimiteDiario(response.data.originalDailyLimit);
      setNovoLimiteNoturno(response.data.originalOvernightLimit);
      setNovoLimiteTransacao(response.data.originalTransactionLimit);
    } catch (error) {
      toast.error('Erro ao verificar as data de solicitações de limite.');
    } finally {
      setLoader(false);
    }
  };

  const currencyConfig = {
    locale: 'pt-BR',
    formats: {
      number: {
        BRL: {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  const override = css`
    display: flex;
    margin: 20px auto;
    border-color: red;
  `;

  const getLimits = async () => {
    try {
      setLoader(true);
      const limits = await getLimitsFitBank();
      setLimiteDiario(parseFloat(limits.data.dailyMaxValue));
      setNovoLimiteDiario(parseFloat(limits.data.dailyMaxValue));
      setOriginalLimiteDiario(parseFloat(limits.data.originalDailyMaxValue));
      setLimiteNoturno(parseFloat(limits.data.overnightMaxValue));
      setNovoLimiteNoturno(parseFloat(limits.data.overnightMaxValue));
      setOriginalLimiteNoturno(
        parseFloat(limits.data.originalOvernightMaxValue)
      );
      setLimiteTransacao(parseFloat(limits.data.transactionMaxValue));
      setNovoLimiteTransacao(parseFloat(limits.data.transactionMaxValue));
      setOriginalLimiteTransacao(
        parseFloat(limits.data.originalTransactionMaxValue)
      );
    } catch (err) {
      toast.error(
        'Ocorreu um erro ao obter os limites, tente novamente mais tarde'
      );
    } finally {
      setLoader(false);
    }
  };
  const setLimits = async (type) => {
    switch (type) {
      case 'daily':
        setDiarioLoading(true);
        break;
      case 'overnight':
        setNoturnoLoading(true);
        break;
      case 'transaction':
        setTransacaoLoading(true);
        break;
      default:
        break;
    }

    let values = [];
    if (type === 'daily') {
      if (novoLimiteDiario < originalLimiteDiario) {
        values.push({
          type: 0,
          originalValue: originalLimiteDiario,
          newValue: novoLimiteDiario,
          justification,
        });
      }
    }

    if (type === 'overnight') {
      if (novoLimiteNoturno < originalLimiteNoturno) {
        values.push({
          type: 3,
          originalValue: originalLimiteNoturno,
          newValue: novoLimiteNoturno,
          justification,
        });
      }
    }

    if (type === 'transaction') {
      if (novoLimiteTransacao < originalLimiteTransacao) {
        values.push({
          type: 4,
          originalValue: originalLimiteTransacao,
          newValue: novoLimiteTransacao,
          justification,
        });
      }
    }

    if (!values.length) {
      toast.error('Os valores são iguais');
      setDiarioLoading(false);
      setNoturnoLoading(false);
      setTransacaoLoading(false);
      return;
    }

    const bank = localStorage.getItem('bank');

    try {
      if (bank === '450') {
        const response = await setLimitsFitBank(values);
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.success(
            'Solicitação de alteração de limite realizada com sucesso'
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao solicitar alteração de limite');
    }

    switch (type) {
      case 'daily':
        setDiarioLoading(false);
        break;
      case 'overnight':
        setNoturnoLoading(false);
        break;
      case 'transaction':
        setTransacaoLoading(false);
        break;
      default:
        break;
    }
  };

  const hasChangeModal =
    (selectLimits === 'daily' && modalLimiteDiario !== originalLimiteDiario) ||
    (selectLimits === 'overnight' &&
      modalLimiteNoturno !== originalLimiteNoturno) ||
    (selectLimits === 'transaction' &&
      modalLimiteTransacao !== originalLimiteTransacao);

  const handleJustificationChange = (event) => {
    setJustification(event.target.value);
  };
  const handleFileChange = (event) => {
    setUploadedFile(event.target.files[0]);
  };

  const validateFile = (file) => {
    const allowedTypes = [
      'image/jpeg',
      'image/jpg',
      'image/png',
      'application/pdf',
    ];

    if (!file) {
      toast.error('Por favor, escolha um arquivo.');
      return false;
    }

    if (!allowedTypes.includes(file.type)) {
      toast.error('Tipo de arquivo não permitido.');
      return false;
    }

    return true;
  };

  const blobToFile = (blob, filename) => {
    return new File([blob], filename, { type: blob.type });
  };

  const fileToBase64 = (file) => {
    return new Promise((resolve, reject) => {
      if (!(file instanceof Blob)) {
        return reject(new TypeError('O parâmetro não é do tipo Blob'));
      }
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  const processFile = async (file) => {
    if (!(file instanceof File)) {
      file = blobToFile(file, 'converted-file');
    }

    try {
      const base64Data = await fileToBase64(file);
      return base64Data;
    } catch (error) {
      console.error('Erro ao processar o arquivo:', error);
    }
  };
  async function RegisterDocuments({ type, name, format, file }) {
    const documentData = {
      representativeId: null,
      documentFile: file,
      documentFormat: format,
      documentName: name,
      documentType: type,
      description: name,
    };

    try {
      const response = await apiV1.post('/document/store/update', documentData);
      if (!response || !response.data) {
        throw new Error('Resposta da API não está definida.');
      }
      if (response.status !== 200) {
        throw new Error(`Erro HTTP! Status: ${response.status}`);
      }
      const result = response.data;
      return result;
    } catch (error) {
      console.error('Erro ao registrar documento:', error);
      return {
        error:
          error.response?.data?.message ||
          error.message ||
          'Erro ao registrar documento.',
      };
    }
  }

  const setModalLimits = async (type) => {
    const fileInput = document.getElementById('fileInput');
    if (!fileInput.files.length) {
      toast.error('Por favor, selecione um arquivo.');
      setModalLoading(false);
      return;
    }

    const file = fileInput.files[0];
    if (!validateFile(file)) {
      setModalLoading(false);
      return;
    }

    if (justification === '') {
      toast.error('Selecione uma justificativa válida');
      setModalLoading(false);
      return;
    }

    setModalLoading(true);

    let values = {};
    let requestLimitResponse = null;
    let _document = null;

    const ensureNumber = (value) => {
      const numberValue = parseFloat(value);
      if (isNaN(numberValue)) {
        throw new Error('Valor deve ser um número válido');
      }
      return numberValue;
    };

    try {
      if (!(file instanceof Blob)) {
        throw new Error('O arquivo não é do tipo Blob');
      }

      const extratoBase64 = await fileToBase64(file);

      const extratoFile = await uploadS3(
        `extrato.${file.type.split('/')[1]}`,
        extratoBase64
      );
      _document = {
        documentFile: extratoFile,
        documentType: documentTypes.compRenda,
        documentName: 'Extrato',
        documentFormat: getDocumentFormat(file.type.split('/')[1]),
        description: 'Extrato',
      };

      _setDocument(_document);

      const registeredDocument = await RegisterDocuments({
        type: documentTypes.compRenda,
        name: 'Extrato',
        format: getDocumentFormat(file.type.split('/')[1]),
        file: extratoFile,
      });
      _document = registeredDocument;
    } catch (uploadErr) {
      toast.error('Erro ao enviar o arquivo');
      setModalLoading(false);
      console.error('Erro ao enviar o arquivo:', uploadErr);
      return;
    }

    if (type === 'daily' && modalLimiteDiario > originalLimiteDiario) {
      requestLimitResponse = await requestPixLimit(
        0,
        ensureNumber(modalLimiteDiario),
        _document,
        justification
      );
      if (!requestLimitResponse.success) {
        toast.error(
          requestLimitResponse.message ||
            'Erro ao verificar solicitação de aumento de limite'
        );
        setModalLoading(false);
        return;
      }
      values.daily = {
        originalValue: ensureNumber(originalLimiteDiario),
        newValue: ensureNumber(modalLimiteDiario),
        justification,
      };
    }

    if (type === 'overnight' && modalLimiteNoturno > originalLimiteNoturno) {
      requestLimitResponse = await requestPixLimit(
        3,
        ensureNumber(modalLimiteNoturno),
        _document,
        justification
      );
      if (!requestLimitResponse.success) {
        toast.error(
          requestLimitResponse.message ||
            'Erro ao verificar solicitação de aumento de limite'
        );
        setModalLoading(false);
        return;
      }
      values.overnight = {
        originalValue: ensureNumber(originalLimiteNoturno),
        newValue: ensureNumber(modalLimiteNoturno),
        justification,
      };
    }

    if (
      type === 'transaction' &&
      modalLimiteTransacao > originalLimiteTransacao
    ) {
      requestLimitResponse = await requestPixLimit(
        4,
        ensureNumber(modalLimiteTransacao),
        _document,
        justification
      );
      if (!requestLimitResponse.success) {
        toast.error(
          requestLimitResponse.message ||
            'Erro ao verificar solicitação de aumento de limite'
        );
        setModalLoading(false);
        return;
      }
      values.transaction = {
        originalValue: ensureNumber(originalLimiteTransacao),
        newValue: ensureNumber(modalLimiteTransacao),
        justification,
      };
    }

    if (Object.keys(values).length === 0) {
      toast.error('Os valores são iguais ou menores que os limites atuais');
      setModalLoading(false);
      return;
    }

    const hasInvalidItems = Object.values(values).some((item, index) => {
      return item.newValue == null || typeof item.newValue !== 'number';
    });

    if (hasInvalidItems) {
      toast.error(
        'Um ou mais itens não possuem a propriedade newValue como um número.'
      );
      setModalLoading(false);
      return;
    }

    const banco = localStorage.getItem('bank');

    try {
      if (banco === '450') {
        const response = await requestPixLimit(values);
        if (response.error) {
          toast.error(response.error);
        } else {
          toast.success(
            'Solicitação de alteração de limite realizada com sucesso'
          );
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        }
      } else {
        toast.error(
          'Solicitação de alteração de limite não é válida para este banco.'
        );
      }
    } catch (err) {
      toast.error('Ocorreu um erro ao solicitar alteração de limite');
    }

    setModalLoading(false);
    setTimeout(() => {
      onRequestClose();
      window.location.reload();
    }, 3000);
  };

  const handleChange = (event, value) => {
    const { id } = event.target;

    switch (id) {
      case 'limiteDiario':
        setNovoLimiteDiario(value);
        setDiarioChanged(value !== originalLimiteDiario && value !== '');
        break;
      case 'limiteNoturno':
        setNovoLimiteNoturno(value);
        setNoturnoChanged(value !== originalLimiteNoturno && value !== '');
        break;
      case 'limiteTransacao':
        setNovoLimiteTransacao(value);
        setTransacaoChanged(value !== originalLimiteTransacao && value !== '');
        break;
      default:
        break;
    }
  };

  const handleModalChange = (event, value, maskedValue) => {
    if (selectLimits === 'daily') {
      setModalLimiteDiario(Number(value));
    } else if (selectLimits === 'overnight') {
      setModalLimiteNoturno(Number(value));
    } else if (selectLimits === 'transaction') {
      setModalLimiteTransacao(Number(value));
    }
  };

  const checkChanges = () => {
    if (
      limiteDiario !== novoLimiteDiario ||
      limiteNoturno !== novoLimiteNoturno ||
      limiteTransacao !== novoLimiteTransacao
    ) {
      setHasChanges(true);
    } else {
      setHasChanges(false);
    }
  };

  useEffect(() => {
    setLoader(true);
    /*checkLimitRequests();*/
    getLimits();
    setLoader(false);
  }, []);

  useEffect(() => {
    checkChanges();
  }, [novoLimiteDiario, novoLimiteNoturno, novoLimiteTransacao]);

  const handleLimitClick = (limitType) => {
    setIsOpenModal(true);
    setSelectLimits(limitType);
  };

  return (
    <>
      <ReactModal
        isOpen={isOpenModal}
        className={'change-modal'}
        overlayClassName={'overlay-change-password-modal'}
      >
        <BoxModal
          maxWidth={700}
          title="Solicitar aumento de limite"
          close={() => setIsOpenModal(false)}
        >
          <p>
            Gostaríamos de informar que, conforme a política da nossa
            instituição, as solicitações de aumento de limite podem ser feitas
            apenas a cada 90 dias. Isso significa que, após uma solicitação de
            aumento de limite, será necessário aguardar um período de 90 dias
            antes de poder realizar uma nova solicitação.
          </p>

          <div className="input-field">
            <label>
              Limite <Asterisk />
            </label>
            <div>
              <IntlCurrencyInput
                currency="BRL"
                config={currencyConfig}
                onChange={handleModalChange}
                defaultValue={
                  selectLimits === 'daily'
                    ? Number(modalLimiteDiario)
                    : selectLimits === 'overnight'
                    ? Number(modalLimiteNoturno)
                    : Number(modalLimiteTransacao)
                }
                value={
                  selectLimits === 'daily'
                    ? Number(modalLimiteDiario)
                    : selectLimits === 'overnight'
                    ? Number(modalLimiteNoturno)
                    : Number(modalLimiteTransacao)
                }
                id={
                  selectLimits === 'daily'
                    ? 'modalLimiteDiario'
                    : selectLimits === 'overnight'
                    ? 'modalLimiteNoturno'
                    : 'modalLimiteTransacao'
                }
                min={
                  selectLimits === 'daily'
                    ? originalLimiteDiario
                    : selectLimits === 'overnight'
                    ? originalLimiteNoturno
                    : originalLimiteTransacao
                }
              />
              <MessageOrigin
                limite={
                  selectLimits === 'daily'
                    ? originalLimiteDiario
                    : selectLimits === 'overnight'
                    ? originalLimiteNoturno
                    : originalLimiteTransacao
                }
              />
              <br />
            </div>
            <label>Extrato</label>
            <Asterisk />
            <div>
              <input
                type="file"
                accept=".jpg, .jpeg, .png, .pdf"
                id="fileInput"
                onChange={handleFileChange}
              />
              <MessageExtract />
            </div>
            <label>
              Justificativa <Asterisk />
            </label>
            <FilterItem className="filter-item col-md-5">
              <select
                value={justification}
                onChange={handleJustificationChange}
                required
              >
                <option value="" className="filter-item-option" hidden>
                  Selecione
                </option>
                <option value={0} className="filter-item-option">
                  Negócios
                </option>
                <option value={1} className="filter-item-option">
                  Renda
                </option>
                <option value={2} className="filter-item-option">
                  Emergência
                </option>
                <option value={3} className="filter-item-option">
                  Educação
                </option>
                <option value={4} className="filter-item-option">
                  Saúde
                </option>
                <option value={5} className="filter-item-option">
                  Viagem
                </option>
                <option value={6} className="filter-item-option">
                  Renovação
                </option>
                <option value={7} className="filter-item-option">
                  Outros
                </option>
              </select>
            </FilterItem>
          </div>
          <div className="button-area">
            <button
              type="submit"
              className="confirm-button"
              onClick={() => setModalLimits(selectLimits)}
              disabled={!hasChangeModal || modalLoading}
            >
              {modalLoading ? (
                <BeatLoader
                  color="white"
                  loading={modalLoading}
                  css={override}
                  size={20}
                />
              ) : (
                'Confirmar'
              )}
            </button>
          </div>
        </BoxModal>
      </ReactModal>
      <div className="limites-pix">
        <div className="limites-pix-container col-md-10">
          <div className="header">
            <div className="col-xl-12">
              <label>
                <Link to={'/pagina-principal'}>
                  Pagina inicial
                  <MdKeyboardArrowRight size={20} />
                </Link>
                <Link to={'/pagina-pix'}>
                  Pix
                  <MdKeyboardArrowRight size={20} />
                </Link>
                Meus Limites Pix
              </label>
              <p>Ajustar Limites Pix</p>
              <div className="info-container">
                <AiOutlineInfoCircle />
                <span className="information-message">
                  Os ajustes de limites estão temporariamente desativados
                </span>
              </div>
            </div>
          </div>
          <div className="body">
            {loader ? (
              <BeatLoader
                color={'var(--secondary-color)'}
                loading={loader}
                css={override}
                size={20}
              />
            ) : (
              <>
                <div className="input-field mb-20">
                  <label>
                    Limite Diário <Asterisk />
                  </label>
                  <div>
                    {novoLimiteDiario === 0 ? (
                      <BeatLoader
                        color={'var(--secondary-color)'}
                        loading={true}
                        css={override}
                        size={20}
                      />
                    ) : (
                      <IntlCurrencyInput
                        currency="BRL"
                        config={currencyConfig}
                        onChange={(event, value) => handleChange(event, value)}
                        defaultValue={novoLimiteDiario}
                        id={'limiteDiario'}
                        min={0}
                        max={originalLimiteDiario}
                        readOnly
                      />
                    )}
                  </div>
                  {/*  <a
                    onClick={
                      !diarioSolicitado
                        ? () => handleLimitClick('daily')
                        : undefined
                    }
                    className={
                      diarioSolicitado ? 'link-disabled' : 'link-enabled'
                    }
                  >
                    {diarioSolicitado
                      ? 'Limite já solicitado'
                      : 'Solicitar aumento de limite'}
                  </a> */}
                 {/*  <div className="button-area">
                    <button
                      className="confirm-button"
                      onClick={() => setLimits('daily')}
                      disabled
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Temporariamente desativado as solicitações de aumento"
                    >
                      {diarioLoading ? (
                        <BeatLoader
                          color="white"
                          loading={diarioLoading}
                          css={override}
                          size={20}
                        />
                      ) : (
                        'Salvar ajustes'
                      )}
                    </button>
                  </div> */}
                </div>

                <div className="input-field mb-20">
                  <hr />
                  <label>
                    Limite Noturno <Asterisk />
                  </label>
                  <div>
                    {novoLimiteNoturno === 0 ? (
                      <BeatLoader
                        color={'var(--secondary-color)'}
                        loading={true}
                        css={override}
                        size={20}
                      />
                    ) : (
                      <IntlCurrencyInput
                        currency="BRL"
                        config={currencyConfig}
                        onChange={(event, value) => handleChange(event, value)}
                        defaultValue={novoLimiteNoturno}
                        id={'limiteNoturno'}
                        min={0}
                        max={originalLimiteNoturno}
                        readOnly
                      />
                    )}
                  </div>
                  {/* <a
                    onClick={
                      !noturnoSolicitado
                        ? () => handleLimitClick('overnight')
                        : undefined
                    }
                    className={
                      noturnoSolicitado ? 'link-disabled' : 'link-enabled'
                    }
                  >
                    {noturnoSolicitado
                      ? 'Limite já solicitado'
                      : 'Solicitar aumento de limite'}
                  </a> */}
                  {/*   <div className="button-area">
                    <button
                      className="confirm-button"
                      onClick={() => setLimits('overnight')}
                      disabled
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Temporariamente desativado as solicitações de aumento"
                    >
                      {noturnoLoading ? (
                        <BeatLoader
                          color="white"
                          loading={noturnoLoading}
                          css={override}
                          size={20}
                        />
                      ) : (
                        'Salvar ajustes'
                      )}
                    </button>
                  </div> */}
                </div>
                <div className="input-field mb-20">
                  <hr />
                  <label>
                    Limite por Transação <Asterisk />
                  </label>
                  <div>
                    {novoLimiteTransacao === 0 ? (
                      <BeatLoader
                        color={'var(--secondary-color)'}
                        loading={true}
                        css={override}
                        size={20}
                      />
                    ) : (
                      <IntlCurrencyInput
                        currency="BRL"
                        config={currencyConfig}
                        onChange={(event, value) => handleChange(event, value)}
                        defaultValue={novoLimiteTransacao}
                        id={'limiteTransacao'}
                        min={0}
                        max={originalLimiteTransacao}
                        readOnly
                      />
                    )}
                  </div>
                  {/* <a
                    onClick={
                      !transacaoSolicitado
                        ? () => handleLimitClick('transaction')
                        : undefined
                    }
                    className={
                      transacaoSolicitado ? 'link-disabled' : 'link-enabled'
                    }
                  >
                    {transacaoSolicitado
                      ? 'Limite já solicitado'
                      : 'Solicitar aumento de limite'}
                  </a> */}
                  {/*  <div className="button-area">
                    <button
                      className="confirm-button"
                      onClick={() => setLimits('transaction')}
                      disabled
                      data-bs-toggle="tooltip"
                      data-bs-placement="bottom"
                      title="Temporariamente desativado as solicitações de aumento"
                    >
                      {transacaoLoading ? (
                        <BeatLoader
                          color="white"
                          loading={transacaoLoading}
                          css={override}
                          size={20}
                        />
                      ) : (
                        'Salvar ajustes'
                      )}
                    </button>
                  </div> */}
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default LimitesPix;
