import React, { useEffect, useState } from "react";
import { TbClipboardCheck, TbCoin, TbCopy } from "react-icons/tb";
import { BeatLoader } from "react-spinners";
import IntlCurrencyInput from "react-intl-currency-input";
import { createQrcodePixFitBank } from "../../../../services/pix.service";
import { css } from "@emotion/react";
import ReactModal from "react-modal";
import { toast } from "react-toastify";
import './Style.css'
import { useAuth } from "../../../../hooks/useAuth";
import { FilterButton, FilterItem, EntryCancel, LoaderDiv, CancelEntry } from "../../../E-check/style";
import { BoxModal } from "../../../boxModal";
import { usePixKey } from "../../../../hooks/usePixKey";

const QrcodeModal = ({ setMostrarQrcodeModal, mostrarQrcodeModal, rows, setKeysModalIsOpen }) => {
  const [qrcodeLoader, setQrcodeLoader] = useState(false);
  const [qrcodeValue, setQrcodeValue] = useState(0);
  const [loader, setLoader] = useState(false);
  const [btnDisabled, setBtnDisabled] = useState(false);
  const [qrcodeBase64, setQrcodeBase64] = useState();
  const [qrCodeRadomKeyModal, setQrCodeRadomKeyModal] = useState(false);
  const [qrcodeCopiaeCola, setQrcodeCopiaeCola] = useState();
  const [isCopied, setIsCopied] = useState(false);

  const { user, account } = useAuth()
  const { pixKeys } = usePixKey();

  const override = css`
    display: flex;
    margin: 0 auto;
    border-color: red;
  `;

  const currencyConfig = {
    locale: "pt-BR",
    formats: {
      number: {
        BRL: {
          style: "currency",
          currency: "BRL",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        },
      },
    },
  };

  function copiarTexto() {
    const textoParaCopiar = document.getElementById("copiaecola-button");

    const campoTexto = document.createElement("textarea");
    campoTexto.value = textoParaCopiar.value;

    document.body.appendChild(campoTexto);

    campoTexto.select();
    campoTexto.setSelectionRange(0, 99999);

    document.execCommand("copy");

    document.body.removeChild(campoTexto);

    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 3000);
  }

  const handleChange = (event, value, maskedValue) => {
    event.preventDefault();

    setQrcodeValue(value);
  };

  const verifyRandomKey = async () => {
    const randomKeyExist = pixKeys.some((key) => key.type == '4');
    if(!randomKeyExist){
      setBtnDisabled(true);
      if (mostrarQrcodeModal && btnDisabled) {
        setQrCodeRadomKeyModal(true);
      }
    } else {
      setBtnDisabled(false);
    }
  }
  
  useEffect(() => {
    verifyRandomKey();
  }, [mostrarQrcodeModal]);

  const redirectToManagementKeysModal = () => {
    setKeysModalIsOpen(true);
    setQrCodeRadomKeyModal(false);
    setMostrarQrcodeModal(false);
  };

  const gerarQrCode = async () => {
    if(user.taxNumber === '12345678900'){
      setQrcodeBase64('https://billfileapi.fitbank.com.br/api/billfile/qrcode/A5551B54D2F95D65D1133ED4A?format=png')
      setQrcodeCopiaeCola('00020126710014br.gov.bcb.pix0136ae42d0d8-b34c-4a8e-afc9-d89f6d43fb9e0209pagamento52040000530398654043.005802BR5925PARCELA X SOLUCOES EM PAG6009Sao Paulo61081360046062290525AE4C79AC5C4924730168F17E363049231')
      return;
    }

    setQrcodeBase64("");
    setQrcodeCopiaeCola("");

    try {
      setQrcodeLoader(true);

      const qrCodeResponse = await createQrcodePixFitBank(
        rows[0].key,
        qrcodeValue
      );
      setQrcodeBase64(qrCodeResponse.data.ImageUrl);
      setQrcodeCopiaeCola(qrCodeResponse.data.QRCodeCopiaeCola);

      setQrcodeLoader(false);
    } catch (err) {
      toast.error(
        "Não foi possível gerar o Qr code, tente novamente mais tarde"
      );
      setQrcodeLoader(false);
    }
  };

  const closeModal = () => {
    setMostrarQrcodeModal(false);
    setQrcodeValue(0);
    setQrcodeBase64("");
    setQrcodeCopiaeCola("");
  };

  return (
    <>
    <ReactModal
      isOpen={mostrarQrcodeModal}
      className={"pix-modal"}
      overlayClassName={"overlay-pix-modal"}
    >
      <div className="qrcode-modal-container">
        <div className="col-md-5">
          <div>
            <h1>Cobrar com PIX</h1>
          </div>
          <div>
            <label>Valor</label>
            <div className="input-area">
              <div className="col-md-1 input-area-icon">
                <TbCoin color="#000" size={18} />
              </div>
              <div className="col-md-11">
                <IntlCurrencyInput
                  currency="BRL"
                  config={currencyConfig}
                  onChange={handleChange}
                  value={qrcodeValue}
                />
              </div>
            </div>
          </div>

          <div className="button-area">
            <button className="cancel-button" onClick={closeModal}>
              Cancelar
            </button>
            <button disabled={btnDisabled} className="confirm-button" onClick={gerarQrCode}>
              Gerar Qr code
            </button>
          </div>
        </div>

        <div className="col-md-5 d-flex justify-content-center align-items-center">
          <div className="qrcode-area">
            {qrcodeBase64 ? (
              <>
                <img src={qrcodeBase64} />
                <div>
                  <button
                    value={qrcodeCopiaeCola}
                    id="copiaecola-button"
                    className="copiaecola-button"
                    onClick={copiarTexto}
                    hidden={isCopied}
                  >
                    Copiar código do QR Code
                    <TbCopy />
                  </button>
                  <button className="copiaecola-button" hidden={!isCopied}>
                    Copiado
                    <TbClipboardCheck color='#FFFFFF' size={24}/>
                  </button>
                </div>
              </>
            ) : (
              <BeatLoader
                color={"var(--secondary-color)"}
                loading={qrcodeLoader}
                css={override}
                size={20}
              />
            )}
          </div>
        </div>
      </div>
    </ReactModal>
    <ReactModal
          isOpen={qrCodeRadomKeyModal}
          className={'change-modal'}
          overlayClassName={'overlay-pix-modal'}
        >
          <BoxModal
            title="Chave Pix"
            close={() => setQrCodeRadomKeyModal(false)}
            maxWidth={500}
          >
            <FilterItem>
              <CancelEntry>
                Sua conta não possui uma chave aleatória <br/><br/> cadastrada, deseja criar uma chave aleatória?
              </CancelEntry>
            </FilterItem>
            <LoaderDiv>
              <EntryCancel onClick={() => setQrCodeRadomKeyModal(false)}>
                Não
              </EntryCancel>
              { loader ? <BeatLoader 
                color={"var(--secondary-color)"}
                loading={loader}
                css={override}
                size={20}
              /> :
              <FilterButton onClick={() => redirectToManagementKeysModal()}>
                Sim
              </FilterButton>
}
            </LoaderDiv>
          </BoxModal>
        </ReactModal>
        </>
  );
};

export default QrcodeModal;
