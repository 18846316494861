import React from "react";
import "./Style.css";


const MessageOrigin = (props) => {
  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  };

  return (
    <div className="limits-message">
      <label>
        <strong>O novo limite deve ser maior que o limite atual.</strong> Limite atual: {formatCurrency(props.limite)}
      </label>
    </div>
  );
};

export default MessageOrigin;
