import React from 'react';
import './Style.css';

const MessageExtract = () => {
  return (
    <div className="limits-message">
      <label>
        <strong>
          O extrato bancário deve ser dos últimos 3 meses de outra instituição
          financeira.
        </strong>
        <p>Formatos de arquivo suportados: PNG, JPEG, JPG, PDF.</p>
      </label>
    </div>
  );
};

export default MessageExtract;
