import React, { useEffect, useState } from "react";
import './style.css';
import EcheckTemplate from "./EcheckTemplate";
import { useNavigate, useParams } from "react-router-dom";
import { useEcheck } from "../../hooks/useEcheck";
import { apiV1 } from "../../lib/axios";
import { toast } from "react-toastify";
import { MdOutlineScreenRotation } from "react-icons/md";
export default function () {
  const [isvisible, setIsVisible] = useState(false);
  const [successfulMessage, setSuccessfulMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isLandscape, setIsLandscape] = useState(window.innerWidth > window.innerHeight);

  const [qrCodeUrl, setQrCodeUrl] = useState('');
  const [echeck, setEcheck] = useState();
  const [pixKey, setPixKey] = useState('');
  const { identifier } = useParams();
  const { getEcheckByIdentifier } = useEcheck();
  const navigate = useNavigate();

  const verifyEcheckIdentifier = async () => {
    try{
      const response = await getEcheckByIdentifier(identifier);
      setEcheck(response.data);

      setIsVisible(true);
      getUrl();
    } catch(err) {
      setIsVisible(false);
    }
  }

  const handleOnChangePixKeyInput = (e) => {
    setPixKey(e.target.value);
  }

  const getUrl = () => {
    const currentURL = window.location.href;
    setQrCodeUrl(currentURL);
  }

  const compensateEcheck = async () => {
    try{
      setIsLoading(true);
      const payload = {
        pixKey,
        echeckIdentifier: identifier
      }
      await apiV1.post('/echeque/transfer', payload);
      setSuccessfulMessage('e-Check compensado com sucesso!');
      setIsLoading(false);
    } catch(err) {
      setIsLoading(false);
      toast.error(err.response.data.message);
    }
  }

  const checkOrientation = () => {
    setIsLandscape(window.innerWidth > window.innerHeight);
  };

  useEffect(() => {
    window.addEventListener('resize', checkOrientation);
    return () => {
      window.removeEventListener('resize', checkOrientation);
    };
  }, []);

  useEffect(()=>{
    verifyEcheckIdentifier();
  },[]);
  return (
    <>
      {isvisible ?
        <>
          {!isLandscape &&
          (
            <div id="rotate-message">
              <p>Gire a tela do seu celular para uma melhor experiência.</p>
              <MdOutlineScreenRotation size={50}/>
            </div>
          )}
          <div className="redeem-page-container" id="content" style={{ display: isLandscape ? 'flex' : 'none' }}>
            <EcheckTemplate qrCodeUrl={qrCodeUrl} echeck={echeck} />

            <div className="redeem-echeck-consult-field">
              { successfulMessage ?
                <div className="fake-modal">
                  <p>{successfulMessage}</p>
                </div>
                :
                <div className="fake-modal input-field">
                  <div className="redeem-echeck-input-field">
                    <label>Insira sua chave pix aqui</label>
                    <div className="input-area">
                        <input onChange={(e) => handleOnChangePixKeyInput(e) }/>
                    </div>
                  </div>
                  <div className="redeem-echeck-button-field">
                    { isLoading
                      ? <button disabled style={{cursor: 'not-allowed'}} > Recebendo </button>
                      : <button onClick={() => {compensateEcheck()}} > Receber e-Check </button>
                    }

                  </div>
                </div>
              }
            </div>
          </div>
        </>
        :
        <div className="d-flex align-items-center justify-content-center mt-4">
          <div className="fake-modal">
            <p>e-Check inválido ou já compensado</p>
            <button onClick={()=> navigate('/')}>Fechar</button>
          </div>
        </div>
      }
    </>
  );
}
