import { DisabledOptionBox, OptionBox, RegisterContainer, RegisterContent, RegisterHeader } from "./style"

export const Register = () => {
  return (
    <RegisterContainer>
      <RegisterHeader>
        <h1>Qual tipo de conta você deseja abrir?</h1>
      </RegisterHeader>

      <RegisterContent>
        <OptionBox to={"/cadastro/pf"}>
          Pessoa Física
        </OptionBox>

        <OptionBox to="/cadastro/pj">
          Pessoa Jurídica
        </OptionBox>
      </RegisterContent>
    </RegisterContainer>
  )
}