import React from "react";
import Acesso from "../acesso-rapido/AcessoRapido";
import { useAuth } from "../../hooks/useAuth";

import "../../components/menu-lateral/Style.css";
import { FiUser } from "react-icons/fi";

export default function () {
  const { user, account } = useAuth()

  const agency = account?.bankBranch || '0000'
  const accountNumber = account?.bankAccount || '0000000000'
  const accountDigit = account?.bankAccountDigit || '0'
  const accountBank = account?.bank || '000'

  return (
    <div className={`menu`}>
        <div className="d-flex header-menu">
          <div className="perfil">
            <div className="client-icon" >
            {
              user?.avatar?.url
              ? (
                <img src={user?.avatar?.url} alt="" />
              ) : (
                <FiUser size={20} />
              )
            }
            </div>
          </div>
          <div className="menu-name" title={user?.name}>
            <div>Olá, {user?.name?.split(' ')[0]} </div>
          </div>
        </div>

        <div className="conta">
            <div className="col-md-3">
              <p style={{'fontSize': '12px','marginBottom': '-5px'}}>Banco</p>
              <p style={{'fontSize': '14px'}}>{accountBank}</p>
            </div>
            <div className="col-md-3">
              <p style={{'fontSize': '12px','marginBottom': '-5px'}}>Ag</p>
              <p style={{'fontSize': '14px'}}>{agency}</p>
            </div>
            <div className="col-md-6">
              <p style={{'fontSize': '12px','marginBottom': '-5px'}}>Conta</p>
              <p style={{'fontSize': '14px'}}>{accountNumber}-{accountDigit}</p>

            </div>
        </div>
        <Acesso />
    </div>
  );
}
